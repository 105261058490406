import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "./NavBar";
import axios from "axios";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Announcementslider from './Announcementslider.jsx';

function Records() {
  const [data, setData] = useState(null);

  const [dailyEntryData, setdailyEntryData] = useState([]);

  const [userPermissions, setUserPermissions] = useState([]);
  const [focusedIndex, setFocusedIndex] = useState(0);
  const [isAuth, setIsAuth] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [isSuperuser, setIsSuperuser] = useState(false);
  const navigate = useNavigate();
  const [latestNews, setLatestNews] = useState(null);
  const [pipelineandturnedup, setPipelineandturnedup] = useState(null);

  const someValue = useState(0);

  useEffect(() => {
    if (localStorage.getItem("access_token") !== null) {
      setIsAuth(true); // Set authentication status to true if access token is present
      fetch("https://scoreboard.backenddd.in/GetAllRecord/", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },

      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          setData(data.records || []); // Update state with fetched data
          setdailyEntryData(data.monthly_records || []); // Update state with fetched data

          setIsSuperuser(data.is_superuser);
          setUserPermissions(data.user_permissions || []);
          setPipelineandturnedup(data.pipelineandturnedup || [])
          setLatestNews(1)

          console.log("data: ", data)
          console.log("is_superuser: ", data.is_superuser)
          console.log("Pipeline and TurnedUp: ", data.is_superuser)


          if (data.is_superuser) {
            console.log("hello")
          }
          else if (data.user_permissions && data.user_permissions.includes('website.update_target')) {
            navigate('/sub_admin_view', { replace: true, state: { userPermissions: "update_target" } });
          }
          else if (data.user_permissions && data.user_permissions.includes('website.custom_viewer_perm')) {
            navigate('/viewers', { replace: true, state: { userPermissions: "custom_viewer_perm" } });
          }
          else if (data.user_permissions && data.user_permissions.includes('website.custom_user_view')) {
            navigate('/UserViews', { replace: true, state: { userPermissions: "custom_user_view" } });
          }

          // Somewhere in your code where you navigate to the AllMinutesOfMeeting component
          else if (data.user_permissions && data.user_permissions.includes('auth.custom_operations')) {
            navigate('/momlist', { replace: true, state: { userPermissions: "custom_operation" } });
          }

          else if (data.user_permissions && data.user_permissions.includes('auth.hr_permissions')) {
            navigate('/hrview', { replace: true, state: { userPermissions: "hr_permission" } })
          }
          else if (data.user_permissions && data.user_permissions.includes('auth.operations')) {
            navigate('/operations', { replace: true, state: { userPermissions: "operations" } })
          }
          else if (data.user_permissions && data.user_permissions.includes('auth.custom_marketting_view')) {
            navigate('/markettingview', { replace: true, state: { userPermissions: "custom_marketting" } })
          }
          else if (data.user_permissions && data.user_permissions.includes('auth.Custom_team_docladder_perm')) {
            navigate('/teamdocladderview', { replace: true, state: { userPermissions: "Custom_team_docladder_perm" } })
          }
          else if (data.user_permissions && data.user_permissions.includes('auth.Custom_team_leader_perm')) {
            navigate('/teamleaderview', { replace: true, state: { userPermissions: "Custom_team_leader_perm" } })
          }
          // assign_associate_targets

          else (
            navigate('/pagenotfound', { replace: true, state: { userPermissions: "notassigned" } })
          )
        })
        .catch((error) => {
          // console.error("There was a problem with the fetch operation:", error);
        });
    }
  }, []);

  // ############################ Iteration ########################################
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(5);
  const totalPages = Math.ceil((data && data.length) / recordsPerPage);

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = data && data.slice(indexOfFirstRecord, indexOfLastRecord);
  const globalRecordCount = (currentPage - 1) * recordsPerPage;

  const paginate = () => {
    // Move to the next page  
    setCurrentPage((prevPage) => (prevPage === totalPages ? 1 : prevPage + 1));
    // setCurrentPage((prevPage) => (prevPage === totalPages ? 4 : 4));
  };

  useEffect(() => {
    if (data && data.length > 0) {
      const focusOnRecord = (index) => {
        setFocusedIndex(index);

        const timer = setTimeout(() => {
          const nextIndex = (index + 1) % recordsPerPage;

          // If the next record is on a new page, paginate to the next page
          if (nextIndex === 0) {
            if (currentPage === totalPages) {
              // Navigate to "/slider" page if not a superuser
              if (
                !isSuperuser &&
                !userPermissions.includes("website.see_records") &&
                !userPermissions.includes("website.update_target")
              ) {
                navigate("/slider");
              }
            } else {
              paginate(); // Move to the next page
            }
          }

          setFocusedIndex(nextIndex);
        }, 5000);

        return () => clearTimeout(timer);
      };

      // Start focusing on the first record
      focusOnRecord(focusedIndex);

      return () => setFocusedIndex(0); // Reset focusedIndex when the component unmounts
    }
  }, [
    focusedIndex,
    data,
    currentPage,
    totalPages,
    paginate,
    navigate,
    isSuperuser,
  ]);

  // ############################ Iteration ########################################

  const openModal = (record) => {
    setSelectedRecord(record);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setSelectedRecord(null);
  };

  const handleUpdate = () => {
    if (selectedRecord) {
      const formData = new FormData();
      formData.append("id", selectedRecord.id);
      formData.append("name", document.getElementById("recordName").value);
      formData.append(
        "resume_forwarded",
        document.getElementById("forwarded").value
      );
      formData.append("pipline", document.getElementById("pipline").value);
      formData.append("turned_up", document.getElementById("turned_up").value);
      formData.append("selected", document.getElementById("selected").value);
      formData.append("target", document.getElementById("target").value);
      // Handle file upload, if available
      const imageInput = document.getElementById("image");
      if (imageInput.files.length > 0) {
        formData.append("image", imageInput.files[0]);
      }

      fetch("https://scoreboard.backenddd.in/UpdateRecord/", {
        method: "PUT",
        body: formData,
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");

          }
          return response.json();
        })
        .then((data) => {
          // console.log("Updated record:", data);
          closeModal();
          // Re-fetch all records after successful update
          fetch("https://scoreboard.backenddd.in/GetAllRecord/", {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
          })
            .then((response) => {
              if (!response.ok) {
                throw new Error("Network response was not ok");
              }
              return response.json();
            })
            .then((data) => {
              setData(data.records || []); // Update state with fetched data
              setdailyEntryData(data.monthly_records || []); // Update state with fetched data

              setIsSuperuser(data.is_superuser);
              setUserPermissions(data.user_permissions || []);
              setPipelineandturnedup(data.pipelineandturnedup || [])
            })
            .catch((error) => {
              // console.error(
              //   "There was a problem with the fetch operation:",
              //   error
              // );
            });
        })
        .catch((error) => {
          // console.error("There was a problem with the fetch operation:", error);
          // Handle errors here
        });
    }
  };

  const handleDelete = (id, e) => {
    e.preventDefault();
    const isConfirmed = window.confirm(
      "Are you sure you want to delete this record?"
    );

    if (isConfirmed) {
      fetch(`https://scoreboard.backenddd.in/DelRecord/${id}/`, {
        method: "DELETE",
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          // console.log("Deleted Successfully");
        })
        .then((data) => {
          // console.log("Deleted record:", data);
          closeModal();

          fetch("https://scoreboard.backenddd.in/GetAllRecord/", {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
          })
            .then((response) => {
              if (!response.ok) {
                throw new Error("Network response was not ok");
              }
              return response.json();
            })
            .then((data) => {
              setData(data.records || []);
              setdailyEntryData(data.monthly_records || []); // Update state with fetched data

              setIsSuperuser(data.is_superuser);
            })
            .catch((error) => {
              // console.error(
              // "There was a problem with the fetch operation:",
              // error
              // );
            });
        })
        .catch((error) => {
          // console.error("There was a problem with the fetch operation:", error);
        });
    }
  };


  function formatDateTime(dateTimeString) {
    const options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
    };
    const formattedDateTime = new Date(dateTimeString).toLocaleString(
      "en-US",
      options
    );
    return formattedDateTime;
  }

  
  const sortedData = [...dailyEntryData].sort((a, b) => {
    // Get the pipeline values from pipelineAndTurnedUpEntry
    const pipelineA = pipelineandturnedup.find(entry => entry.user === a.user)?.pipline || 0;
    const pipelineB = pipelineandturnedup.find(entry => entry.user === b.user)?.pipline || 0;

    // First, sort by revenue in descending order
    if (b.revenue !== a.revenue) {
      return b.revenue - a.revenue; // Sort in descending order by revenue
    }

    // If revenue is the same, sort by selected in descending order
    if (b.selected !== a.selected) {
      return b.selected - a.selected; // Sort in descending order by selected
    }

    // If selected is the same, sort by resume_forwarded in descending order
    if (b.resume_forwarded !== a.resume_forwarded) {
      return b.resume_forwarded - a.resume_forwarded; // Sort in descending order by resume forwarded
    }

    // If resume_forwarded is the same, sort by pipeline in descending order
    if (pipelineB !== pipelineA) {
      return pipelineB - pipelineA;
    }
    
    // If pipeline is the same, sort by turned up in descending order
    if (b.turned_up !== a.turned_up) {
      return b.turned_up - a.turned_up; // Sort in descending order by turned up
    }
    
    return 0; // If all criteria are the same, keep the original order
  });


  return (
    <>
      <Navbar />
      <section>
        <div>
          <div>
            <div>
              <div>
                {isSuperuser && (
                  <table className="min-w-full bg-white border border-gray-300" style={{ width: "95%", margin: "auto" }}>
                    <thead>
                      <tr>
                        <th scope="col" className="py-2 px-4 border-b">
                          HR
                        </th>
                        <th scope="col" className="py-2 px-4 border-b">
                          Name
                        </th>
                        <th scope="col" className="py-2 px-4 border-b">
                          Forwarded
                        </th>
                        <th scope="col" className="py-2 px-4 border-b">
                          Pipeline
                        </th>
                        <th scope="col" className="py-2 px-4 border-b">
                          Turned Up
                        </th>
                        <th scope="col" className="py-2 px-4 border-b">
                          Selected Candidate
                        </th>
                        <th scope="col" className="py-2 px-4 border-b">
                          Revenue
                        </th>

                        <th scope="col" className="py-2 px-4 border-b">
                          Options
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-300">
                      {dailyEntryData &&
                        sortedData.map((dailyEntry) => {
                          // Find the corresponding record in data
                          const correspondingRecord = data.find((record) => record.id === dailyEntry.user);

                          // If a corresponding record is found, extract the image and name
                          const imageSrc = correspondingRecord ? `https://scoreboard.backenddd.in${correspondingRecord.image}` : '';
                          const userName = correspondingRecord ? correspondingRecord.name : '';
                          const targetrev = correspondingRecord ? correspondingRecord.revenue : '';

                          // Find the corresponding entry in pipelineandturnedup
                          const pipelineAndTurnedUpEntry = pipelineandturnedup.find((entry) => entry.user === dailyEntry.user);

                          // If corresponding entry is found, extract the values
                          const pipeline = pipelineAndTurnedUpEntry ? pipelineAndTurnedUpEntry.pipline : '';
                          // const turnedUp = pipelineAndTurnedUpEntry ? pipelineAndTurnedUpEntry.turned_up : '';

                          return (
                            <tr key={dailyEntry.user}>
                              <td className="py-2 px-4 border-b text-center">
                                {/* Display the image if available */}
                                {imageSrc && (
                                  <img
                                    src={imageSrc}
                                    className="w-auto"
                                    style={{ height: "100px" }}
                                    alt=""
                                  />
                                )}
                              </td>
                              <td className="py-2 px-4 border-b text-center">
                                {/* Display the user name if available */}
                                {userName && <span>{userName}</span>}
                              </td>
                              {/* Add other table data cells as needed */}
                              <td className="py-2 px-4 border-b text-center">
                                {dailyEntry.resume_forwarded}
                              </td>
                              {/* Display pipeline value */}
                              <td className="py-2 px-4 border-b text-center">
                                {pipeline}
                              </td>
                              {/* Display turned_up value */}
                              <td className="py-2 px-4 border-b text-center">
                                {dailyEntry.turned_up}
                              </td>
                              <td className="py-2 px-4 border-b text-center">
                                {dailyEntry.selected}
                              </td>
                              <td className="py-2 px-4 border-b text-center">{isNaN(dailyEntry.revenue) ? 0 : parseFloat(dailyEntry.revenue)} / {targetrev} lac</td>


                              <td className="py-2 px-4 border-b text-center">
                                <button
                                  className="btn btnChange"
                                  onClick={() => openModal(dailyEntry)}
                                >
                                  <i className="fa-solid fa-pen-to-square"></i>
                                </button>
                              </td>
                            </tr>
                          );
                        })}

                    </tbody>
                  </table>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>

      <Announcementslider />

      {showModal && (
        <div
          className="modal"
          tabIndex="-1"
          role="dialog"
          style={{ display: "block" }}
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Edit Record</h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={closeModal}
                ></button>
              </div>
              <div className="modal-body">
                {selectedRecord && (
                  <form class="myFormData">
                    <input
                      type="hidden"
                      className="form-control"
                      id="recordId"
                      value={selectedRecord.id}
                    />

                    <div className="mb-3">
                      <label htmlFor="recordName" className="form-label">
                        Record Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="recordName"
                        value={selectedRecord.name}
                      />
                    </div>

                    <div className="mb-3">
                      <label htmlFor="recordName" className="form-label">
                        Username
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="recordName"
                        value={selectedRecord.username}
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="forwarded" className="form-label">
                        Resume Forwarded
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        id="forwarded"
                        // value="0"
                        placeholder={selectedRecord.resume_forwarded}
                        onChange={(e) =>
                          setSelectedRecord({
                            ...selectedRecord,
                            resume_forwarded: e.target.value,
                          })
                        }
                        readOnly={
                          !isSuperuser &&
                          !userPermissions.includes("website.see_records") &&
                          userPermissions.includes("website.update_target")
                        }
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="pipline" className="form-label">
                        Pipeline
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        id="pipline"
                        placeholder={selectedRecord.pipline}
                        // value="0"
                        onChange={(e) =>
                          setSelectedRecord({
                            ...selectedRecord,
                            pipline: e.target.value,
                          })
                        }
                        readOnly={
                          !isSuperuser &&
                          !userPermissions.includes("website.see_records") &&
                          userPermissions.includes("website.update_target")
                        }
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="turned_up" className="form-label">
                        Turned Up
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        id="turned_up"
                        // value={selectedRecord.turned_up !== null ? selectedRecord.turned_up : 0}
                        onChange={(e) =>
                          setSelectedRecord({
                            ...selectedRecord,
                            turned_up: e.target.value,
                          })
                        }
                        placeholder={selectedRecord.turned_up}
                        readOnly={
                          !isSuperuser &&
                          !userPermissions.includes("website.see_records") &&
                          userPermissions.includes("website.update_target")
                        }
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="selected" className="form-label">
                        Selected
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        id="selected"
                        placeholder={selectedRecord.selected}
                        onChange={(e) =>
                          setSelectedRecord({
                            ...selectedRecord,
                            selected: e.target.value,
                          })
                        }
                        readOnly={
                          !isSuperuser &&
                          !userPermissions.includes("website.see_records") &&
                          userPermissions.includes("website.update_target")
                        }
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="target" className="form-label">
                        Target
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        id="target"
                        placeholder={selectedRecord.target}

                        onChange={(e) =>
                          setSelectedRecord({
                            ...selectedRecord,
                            target: e.target.value,
                          })
                        }
                        readOnly={
                          !isSuperuser &&
                          !userPermissions.includes("website.update_target") &&
                          userPermissions.includes("website.see_records")
                        }
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="image" className="form-label">
                        Image URL
                      </label>

                      <input
                        type="file"
                        className="form-control"
                        id="image"
                        onChange={(e) =>
                          setSelectedRecord({
                            ...selectedRecord,
                            image: e.target.value,
                          })
                        }
                        readOnly={
                          !(
                            !isSuperuser &&
                            userPermissions.includes("website.update_target") &&
                            !userPermissions.includes("website.see_records")
                          )
                        }
                      />
                    </div>
                    <div className="btncontainer">
                      <button
                        type="button"
                        className="btn btn-primary m-2"
                        onClick={handleUpdate}
                      >
                        Save Changes
                      </button>
                      <button
                        className="btn btn-danger m-2"
                        onClick={(e) => handleDelete(selectedRecord.id, e)}
                      >
                        Delete
                      </button>
                      <button
                        type="button"
                        className="btn btn-secondary m-2"
                        onClick={closeModal}
                      >
                        Close
                      </button>
                    </div>
                  </form>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
export default Records;
